import React from "react"
import { graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import {
  Typography,
  Layout,
  SEO,
  Grid,
  GridCell,
  Footer,
  InfoBlock,
  Wrapper,
} from "../components"
import { breakpoints } from "../styles/breakpoints"

const ThumbnailImage = styled(Img)`
  height: 221px;
  width: 260px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;

  @media (max-width: ${breakpoints.PHONE}) {
    width: 100%;
    height: 200px;
    border-top-right-radius: inherit;
    border-bottom-left-radius: initial;
  }
`

export default function Template({ data }) {
  const { allMdx } = data
  const posts = allMdx.edges
  return (
    <Layout page="/whitepaper">
      <SEO title="Whitepapers" />

      <InfoBlock>
        <Wrapper width={884}>
          <Grid
            css={`
              height: 314px;
              display: flex;
              align-items: center;

              @media (max-width: ${breakpoints.PHONE}) {
                height: auto;
              }
            `}
          >
            <GridCell span={12}>
              <Typography tag="h1" use="headline2">
                Expand your knowledge with our collection of whitepaper primers
              </Typography>
            </GridCell>
          </Grid>
        </Wrapper>
      </InfoBlock>

      <Wrapper width={884}>
        <Grid>
          {posts.map(({ node: post }, index) => {
            return (
              <GridCell
                key={`${post.frontmatter.path}-${index}`}
                desktop={12}
                tablet={8}
                phone={4}
                css={`
                  padding-bottom: calc(
                    var(--gutter-1) - var(--mdc-layout-grid-margin-desktop)
                  );
                `}
              >
                {/* Card */}
                <div
                  css={`
                    /* Card styles lifted from MDC */
                    /* As the card is a link we should add focus styles */
                    cursor: pointer;
                    outline: none;
                    display: flex;
                    height: 220px;
                    border-radius: 20px;
                    border: 1px solid #e5e6ec;
                    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.16),
                      0 1px 1px 0 rgba(0, 0, 0, 0.16),
                      0 1px 3px 0 rgba(0, 0, 0, 0.16);

                    @media (max-width: ${breakpoints.PHONE}) {
                      flex-direction: column;
                      height: 450px;
                    }
                  `}
                  role="link"
                  tabIndex={0}
                  onClick={() => {
                    navigate(post.frontmatter.path + '/')
                  }}
                >
                  {/* <div /> used as a wrapper for Gatsby Image to fix flex
                      layout issue. */}
                  <div
                    css={`
                      border-top-left-radius: inherit;
                      border-bottom-left-radius: inherit;

                      @media (max-width: ${breakpoints.PHONE}) {
                        border-top-right-radius: inherit;
                        border-bottom-left-radius: initial;
                      }
                    `}
                  >
                    {/* Thumbnail */}
                    <ThumbnailImage
                      fluid={post.frontmatter.image.childImageSharp.fluid}
                      alt="Thumbnail"
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                  </div>

                  {/* Text content */}
                  <div
                    css={`
                      padding: 16px;
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;

                      @media (max-width: ${breakpoints.PHONE}) {
                        height: 100%;
                      }
                    `}
                  >
                    {/* Blog post title */}
                    <Typography
                      use="subtitle1"
                      tag="h3"
                      css={`
                        color: var(--text-color) !important;
                        font-weight: bold;
                        font-size: 25px !important;
                      `}
                    >
                      {post.frontmatter.title}
                    </Typography>
                    {post.frontmatter.author && (
                      <Typography tag="h4" use="subtitle2">
                        by {post.frontmatter.author}
                      </Typography>
                    )}
                    {/* Blog post excerpt */}
                    <Typography
                      use="body1"
                      tag="p"
                      css={`
                        color: var(--text-color-light) !important;
                        margin-bottom: 16px;
                        height: 72px;
                        font-weight: medium;
                        /* Line clamping ftw. */
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                      `}
                    >
                      {post.excerpt}
                    </Typography>

                    {/* Blog post link */}
                    <Typography
                      use="body1"
                      css={`
                        font-weight: bold;

                        @media (max-width: ${breakpoints.PHONE}) {
                          text-align: center;
                        }
                      `}
                    >
                      Read more
                    </Typography>
                  </div>
                </div>
              </GridCell>
            )
          })}
        </Grid>
      </Wrapper>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query WhitepapersQuery {
    allMdx(
      limit: 1000
      sort: {
        fields: [frontmatter___date, frontmatter___title, frontmatter___author]
        order: [ASC, ASC]
      }
      filter: { frontmatter: { published: { eq: true }, type: {eq: "whitepaper"} } }
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            path
            title
            author
            date(formatString: "DD MMMM, YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
